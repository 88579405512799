import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from './actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons/faX';
import { menuOpen, menuClose, fadeIn, fadeOut } from 'Shared/animations';
import { breakpoint, color } from '@ifixit/primitives';

const animDuration = 400;

const Container = styled.div`
   z-index: 1000;
   position: fixed;
   display: flex;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
   max-height: ${props => props.height || '50em'};
   max-width: ${props => props.width || '50em'};
   background: white;
   border-radius: 8px;
   overflow: auto;
   box-shadow:
      0px 16px 36px rgba(16, 22, 26, 0.2),
      0px 3px 10px rgba(16, 22, 26, 0.2);
   animation: ${props => (props.$isClosing ? menuClose : menuOpen)} 0.45s;

   > div {
      flex: 1;
      max-width: 100%;
   }

   @media screen and (max-width: ${breakpoint.md}) {
      max-height: none;
      max-width: none;
      border-radius: 0;

      @media (orientation: landscape) {
         display: block;
      }
   }
`;

const Overlay = styled.div`
   z-index: 999;
   display: ${props => (props.$isOpen || props.$isClosing ? 'block' : 'none')};
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: rgba(0, 3, 6, 0.38);
   cursor: ${props => (props.$isLocked ? 'default' : 'pointer')};
   animation: ${props => (props.$isClosing ? fadeOut : fadeIn)} 0.45s;
`;

const CloseButton = styled.div`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   right: 0;
   height: 60px;
   width: 60px;
   cursor: pointer;
`;

const mapStateToProps = state => ({
   popupState: state.popupState,
});

const mapDispatchToProps = dispatch => ({
   popPopup: () => dispatch(Actions.popPopup()),
});

const PopupModal = ({ popupState, popPopup }) => {
   const [overlayClosing, setOverlayClosing] = useState(false);
   const [popupStack, setPopupStack] = useState(popupState.popupStack);

   useEffect(() => {
      let newPopupStack = popupState.popupStack;

      // If a popup has been closed, animate it closing before popping it from
      // the stack.
      if (popupStack.length > newPopupStack.length) {
         newPopupStack = popupStack.map((popup, index) => ({
            ...popup,
            props: {
               ...popup.props,
               $isClosing: index === popupStack.length - 1,
            },
         }));
      }

      setPopupStack(newPopupStack);
      setOverlayClosing(popupState.popupStack.length === 0 && newPopupStack.length > 0);
   }, [popupState.popupStack]);

   useEffect(() => {
      const body = document.querySelector('body');
      body.style.overflow = popupStack.length > 0 ? 'hidden' : 'auto';

      if (overlayClosing && popupStack.length > 0) {
         const timer = setTimeout(() => {
            setOverlayClosing(false);
            setPopupStack([]);
         }, animDuration);

         return () => clearTimeout(timer);
      }
   }, [popupStack.length, overlayClosing]);

   if (popupStack.length === 0) {
      return null;
   }

   const curPopup = popupStack.at(-1);
   // Default values are now handled in the destructuring
   const {
      isLocked = false,
      hideClose = false,
      onConfirm = () => {},
      onDecline = () => {},
      onCancel = () => {},
   } = curPopup;

   return (
      <Fragment>
         <Overlay
            onClick={isLocked ? null : popPopup}
            $isClosing={overlayClosing}
            $isOpen={popupStack.length > 0}
            $isLocked={isLocked}
         />
         {popupStack.map((popup, index) => {
            const isOpen = index === popupStack.length - 1;
            const PopupComponent = popup.component;
            if (PopupComponent === undefined) {
               throw new TypeError(
                  `Popup ${index} missing component: ${popup.dedup}\n${JSON.stringify(popup)}`
               );
            }

            return (
               <Container key={`popup-${index}`} $isOpen={isOpen} {...popup.props}>
                  {!isLocked && !hideClose && (
                     <CloseButton
                        onClick={() => {
                           onCancel();
                           popPopup();
                        }}
                     >
                        <FontAwesomeIcon icon={faX} className="fa-abs-30" color={color.gray[500]} />
                     </CloseButton>
                  )}
                  <PopupComponent
                     index={index}
                     numPopups={popupStack.length}
                     onDecline={onDecline}
                     onConfirm={onConfirm}
                     {...popup.props}
                  />
               </Container>
            );
         })}
      </Fragment>
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupModal);
